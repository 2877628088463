import React from 'react';
import './Frontend.css';
import { BrowserDatamatrixCodeReader, BrowserCodeReader, BrowserMultiFormatReader } from '@zxing/browser';
import { Alert, Box, CircularProgress, Stack, SvgIcon, Typography } from '@mui/material';
import Button from './Button';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import Files from './Files';
import './resizeObserver';
import { DefaultError, useMutation, useQuery } from '@tanstack/react-query';
import { FileService } from '../services';
import { Loading } from '../components/Loading';
import { File as FileModel } from '../models';
import useUploadProgress from './useUploadProgress';
import UploadProgress from './UploadProgress';
import useMessages from './useMessages';
import Messages from './Messages';
import VideoInputDevices from './VideoInputDevices';
import * as pdfjs from 'pdfjs-dist'
import CameraIcon from '@heroicons/react/24/solid/CameraIcon';
import { Reader } from './Reader';
import { useSearchParams } from 'react-router-dom';

let globalCodeReader: any;

const printValue = (value: string): string => {
  let formattedValue: string = '';
  
  if (typeof value === 'string') {
    formattedValue = value;
  } else if (typeof value === 'object') {
    formattedValue = Object.entries(value).map(([ name, value ]: [ string, any ]) => `${name}: ${value}`).join(', ');
  }

  return formattedValue;
};

export default function Frontend() {
  const [ videoInputDevices, setVideoInputDevices ] = React.useState<any[]>([]);
  const [ selectedDeviceId, setSelectedDeviceId ] = React.useState<string | undefined>(undefined);
  const [ noCamera, setNoCamera ] = React.useState(false);
  const [ scanning, setScanning ] = React.useState(false);
  const [ awaitingPermission, setAwaitingPermission ] = React.useState(false);
  const [ permissionGranted, setPermissionGranted ] = React.useState(false);
  const [ permissionRevoked, setPermissionRevoked ] = React.useState(false);
  const [ controls, setControls ] = React.useState<any>(null);
  const [ files, setFiles] = React.useState<FileModel[]>([]);
  const { uploadProgress, onUploadProgress } = useUploadProgress();
  const { messages, addMessage } = useMessages();
  const [ uploading, setUploading ] = React.useState(false);
  const [ decoding, setDecoding ] = React.useState(false);
  const [ scanNumber, setScanNumber ] = React.useState(1);
  const [ fotoNumber, setFotoNumber ] = React.useState(1);
  const [ searchParams ] = useSearchParams();

  const query = useQuery({
    queryKey: [ 'session' ],
    queryFn: FileService.createSession,
  });

  const updateValue = (files: any[]) => {
    const val = (files.length ? `${process.env.REACT_APP_VIEW_BASE_URL}/${query.data?.sessionId}` : '');
    const message = { source: 'erezept', action: 'update', val };

    window.top!.postMessage(message, '*');
  };

  const updateFiles = (files: any[]) => {
    setFiles(files);
    updateValue(files);
  };

  const uploadMutation = useMutation<unknown, DefaultError, any, unknown>({
    mutationFn: async (data: any) => FileService.uploadFile(query.data.sessionId, data.filename, data.filedata, onUploadProgress, data.code),
    onSuccess: (data: any, variables: any) => {
      const newFiles = [ ...files, { id: data.fileId, name: variables.filename, code: variables.code } ];

      updateFiles(newFiles);

      setUploading(false);
    },
    onError: (error: any) => {
      addMessage('warning', error.message);
    },
  });

  const deleteMutation = useMutation<unknown, DefaultError, FileModel, unknown>({
    mutationFn: async (data: FileModel) => FileService.deleteFile(query.data.sessionId, data.id, data.name),
    onSuccess: (data: any, variables: any) => {
      const newFiles = [ ...files ];

      newFiles.splice(newFiles.indexOf(variables), 1);

      updateFiles(newFiles);
    }
  });

  async function checkCamera() {
    // const videoInputDevices = await BrowserCodeReader.listVideoInputDevices();

    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoInputDevices: any[] = [];

    devices.forEach((device: any) => {
      if (device instanceof InputDeviceInfo && device.kind === 'videoinput') {
        videoInputDevices.push(device);
      }
    });

    if (!!videoInputDevices.length) {
      setVideoInputDevices(videoInputDevices);

      let selectedDevice = videoInputDevices[videoInputDevices.length - 1];

      videoInputDevices.forEach((device: any) => {
        const capabilities = device.getCapabilities();

        if (capabilities.facingMode?.includes('environment')) {
          selectedDevice = device;
        }
      });

      setSelectedDeviceId(selectedDevice.deviceId);
    } else {
      setNoCamera(true);
    }
  }

  async function startScan() {
    // const codeReader = new BrowserMultiFormatReader();

    const codeReader = new Reader();

    globalCodeReader = codeReader;

    console.log(`Started decode from camera with id ${selectedDeviceId}`);

    const previewElem = document.querySelector('#erezept-preview');

    setScanning(true);
    setAwaitingPermission(true);
    setPermissionGranted(false);
    setPermissionRevoked(false);

    // you can use the controls to stop() the scan or switchTorch() if available
    const controls = await codeReader.decodeFromVideoDevice(selectedDeviceId, previewElem as HTMLVideoElement, (result, error, controls) => {
      // use the result and error values to choose your actions
      // you can also use controls API in this scope like the controls
      // returned from the method.

      if (result) {
        setUploading(true);

        uploadMutation.mutate({ filename: `E-Rezept-QR-Code ${scanNumber}`, code: result.getText() });

        setScanNumber(scanNumber + 1);

        /*
        setResult(result.getText());

        const newFiles = [ ...files, { name: 'Rezept' } ];

        setFiles2(newFiles);
        */

        controls.stop();

        setControls(null);
        setScanning(false);
      }
    });

    if (controls) {
      setAwaitingPermission(false);
      setPermissionGranted(true);
      setPermissionRevoked(false);
      setControls(controls);
    } else {
      setAwaitingPermission(false);
      setPermissionGranted(false);
      setPermissionRevoked(true);
    }
  }

  async function stopScan() {
    controls?.stop();

    setControls(null);
    setScanning(false);
  }

  async function makeFoto() {
    // const binaryBitmap = BrowserCodeReader.createBinaryBitmapFromCanvas(globalCodeReader.captureCanvas);

    globalCodeReader.captureCanvas.toBlob(function (blob: any) {
      controls?.stop();

      setControls(null);
      setScanning(false);
      setUploading(true);

      uploadMutation.mutate({ filename: `Foto ${fotoNumber}.jpg`, filedata: blob });

      setFotoNumber(fotoNumber + 1);
    }, 'image/jpeg')

    /*
    var frame = new Frame(globalCodeReader.captureCanvas);

    var buffer = frame.toBuffer();

    setUploading(true);

    uploadMutation.mutate({ filename: 'Test.jpg', filedata: buffer });
    */
  }

  React.useEffect(() => {
    checkCamera();
  }, []);

  const onScan = async (): Promise<void> => {
    if (scanning) {
      stopScan();
    } else {
      startScan();
    }
  };

  const getCanvas = (width: number, height: number) : HTMLCanvasElement => {
    const canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;

    return canvas;
  };

  const decodePdf = async (file: string) => {
    pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
    const codeReader = new BrowserDatamatrixCodeReader();

    const pdf: pdfjs.PDFDocumentProxy = await pdfjs.getDocument(file).promise;

    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const scale = 4;
      const viewport = page.getViewport({ scale: scale });
      const width = viewport.width;
      const height = viewport.height;
      const canvas = getCanvas(width, height);
      const canvasContext = (canvas.getContext('2d') as CanvasRenderingContext2D);

      await page.render({ canvasContext, viewport }).promise;

      // const imageData = canvasContext.getImageData(0, 0, width, height);

      return codeReader.decodeFromCanvas(canvas)
    }
  };

  const onDropRecipe = async (acceptedFiles: File[]): Promise<void> => {
    const file = acceptedFiles[0];
    const url = URL.createObjectURL(file);

    const codeReader = new BrowserDatamatrixCodeReader();

    try {
      setDecoding(true);

      let result;

      if (file.type === 'application/pdf') {
        result = await decodePdf(url);
      } else /*if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp')*/ {
        console.log(url);
        result = await codeReader.decodeFromImageUrl(url);
        console.log(result);
      }

      setDecoding(false);
      setUploading(true);

      uploadMutation.mutate({ filename: file.name, filedata: await file.arrayBuffer(), code: result?.getText() });
    } catch (err: any) {
      console.log(err);
      setDecoding(false);
      addMessage('warning', 'Es konnte kein E-Rezept-Code erkannt werden');
    }
  };

  const onDropProduct = async (acceptedFiles: File[]): Promise<void> => {
    const file = acceptedFiles[0];
    setUploading(true);

    uploadMutation.mutate({ filename: file.name, filedata: await file.arrayBuffer() });
  };

  const onDelete = async (file: FileModel): Promise<void> => {
    deleteMutation.mutate(file);
  };

  const onError = async (fileRejections: FileRejection[]): Promise<void> => {
    fileRejections[0].errors.forEach((error: FileError) => {
      addMessage('warning', error.message);
    });
  };

  const recipeDropzone = useDropzone({ onDropAccepted: onDropRecipe, onDropRejected: onError, multiple: false, maxSize: 4194304, noClick: true, noKeyboard: true, accept: {
    'image/png': ['.png'],
    'image/bmp': ['.bmp', '.dib'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp'],
    'application/pdf': ['.pdf'],
  }});

  const productDropzone = useDropzone({ onDropAccepted: onDropProduct, onDropRejected: onError, multiple: false, maxSize: 4194304, noClick: true, noKeyboard: true, accept: {
    'image/png': ['.png'],
    'image/bmp': ['.bmp', '.dib'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp'],
    'application/pdf': ['.pdf'],
  }});

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Loading loading={query.isLoading}>
        {noCamera &&
          <Alert
            severity="warning"
            sx={{
              mb: 1.5,
            }}
          >
            Es wurde keine Kamera gefunden
          </Alert>          
        }
        <Stack
          direction="row"
          spacing={1}
        >
          {scanning && !uploading &&
            <Button label="Fotografieren" onClick={makeFoto} startIcon={<SvgIcon sx={{ fontSize: '1rem' }}><CameraIcon /></SvgIcon>} />
          }
          {!scanning &&
            <Button label="Erfassen" disabled={noCamera || decoding || uploading} onClick={startScan} startIcon={<SvgIcon sx={{ fontSize: '1rem' }}><CameraIcon /></SvgIcon>} />
          }
          {scanning &&
            <Button label="Zurück" disabled={noCamera || decoding || uploading} onClick={stopScan} />
          }
          {/* <Button label="E-Rezept hochladen" onClick={recipeDropzone.open} disabled={scanning || decoding || uploading} /> */}
          {!scanning &&
            <Button label="Hochladen" onClick={productDropzone.open} disabled={scanning || decoding || uploading} />
          }
          <input {...recipeDropzone.getInputProps()} />
          <input {...productDropzone.getInputProps()} />
        </Stack>
        {scanning &&
          <Typography
            variant="body1"
            align="center"
            sx={{ mt: 2 }}
          >
            QR-Code richtig platzieren (<a href={`${searchParams.get('hinweis')}#hinweiserezept`} target="_top">siehe Hinweise E-Rezept</a>) – Scan beginnt automatisch.<br/>
            Oder mit dem Button „Fotografieren“ das gesamte Rezept einfach fotografieren.
          </Typography>
        }
        {decoding &&
          <CircularProgress
            sx={{
              mt: 1.5,
            }}
          />
        }
        <UploadProgress
          uploadProgress={uploadProgress}
          sx={{
            alignSelf: 'stretch',
            mt: 1.5,
          }}
        />
        <Messages
          messages={messages}
          sx={{
            alignSelf: 'stretch',
          }}
        />
        <Files files={files} onDelete={onDelete} />
        <Box className="scan-wrapper" style={{ height: (scanning ? '' : '0'), overflow: 'hidden' }}>
          <div className="scan-border"></div>
          <div className="scan-inner">
            <video id="erezept-preview"></video>
          </div>
          <div className="scan-info">
            {awaitingPermission &&
              <Typography
                variant="body1"
              >
                Bitte erlauben Sie den Zugriff auf die Kamera
              </Typography>
            }
            {permissionRevoked &&
              <Typography
                variant="body1"
              >
                Sie haben den Zugriff auf die Kamera abgelehnt
              </Typography>
            }
          </div>
        </Box>
        {/*
        {scanning && !awaitingPermission && !permissionRevoked &&
          <Box
            sx={{
              border: '1px solid #000',
            }}
          >
            <Typography
              variant="body1"
            >
              {Object.entries(controls.streamVideoCapabilitiesGet()).map(([ name, value ]: [ string, any ]) => (
                <>
                  {name}: {printValue(value)}<br/>
                </>
              ))}
            </Typography>
          </Box>
        }
        */}
        {/*
        <VideoInputDevices
          devices={videoInputDevices}
          onSelect={setSelectedDeviceId}
          selected={selectedDeviceId}
        />
        */}
        {/*
        <p style={{ maxWidth: '90%', wordBreak: 'break-all', color: '#000' }}>
          {result}
        </p>
        */}
      </Loading>
    </Box>
  );
}
