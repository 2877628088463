import React from 'react';
import { IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import DocumentIcon from '@heroicons/react/24/solid/DocumentIcon';
import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon';

const style = {
  padding: '0 10px 10px',
  borderRadius: 1,
  backgroundColor: '#fafafa',
  color: '#000000',
  outline: 'none',
};

export default function FileListItem(props: any) {
  const { file, onDelete } = props;

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={style}
    >
      <IconButton
        sx={{ alignSelf: 'flex-end', marginRight: '-10px' }}
        onClick={() => onDelete(file)}
      >
        <SvgIcon
          sx={{ fontSize: '1rem' }}
        >
          <XMarkIcon/>
        </SvgIcon>
      </IconButton>
      <SvgIcon
        sx={{ fontSize: '3rem' }}
      >
        <DocumentIcon/>
      </SvgIcon>
      <Typography variant="body1">
        {file.name}
      </Typography>
    </Stack>
  );
}
