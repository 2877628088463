import { AxiosProgressEvent } from 'axios';
import requests from './httpService';

const FileService = {
  createSession(): Promise<any> {
    return requests.post('/', {});
  },
  
  uploadFile(sessionId: string, filename: string, data: ArrayBuffer, onUploadProgress: (progressEvent: AxiosProgressEvent) => void, code?: string): Promise<any> {
    return requests.post(`/${sessionId}/${filename}${code ? `?code=${code}` : ''}`, data, { headers: { 'Content-Type': 'application/x-binary' }, onUploadProgress });
  },

  getFiles(sessionId: string): Promise<any> {
    return requests.get(`/${sessionId}`);
  },
  
  getFile(sessionId: string, fileId: string, filename: string): Promise<any> {
    return requests.get(`/${sessionId}/${fileId}/${filename}`);
  },
  
  deleteSession(sessionId: string): Promise<any> {
    return requests.delete(`/${sessionId}`);
  },
  
  deleteFile(sessionId: string, fileId: string, filename: string): Promise<any> {
    return requests.delete(`/${sessionId}/${fileId}/${filename}`);
  },
};

export default FileService;
