import React from 'react';
import { AxiosProgressEvent } from 'axios';

export default function useUploadProgress() {
  const [ uploadProgress, setUploadProgress ] = React.useState(0);

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    console.log(progressEvent.progress);
    
    setUploadProgress(progressEvent.progress!);
  };

  return {
    uploadProgress,
    onUploadProgress,
  };
}
