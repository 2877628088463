import React from 'react';
import { Stack } from '@mui/material';
import FileListItem from './FileListItem';

export default function FileList(props: any) {
  const { sessionId, files, onDelete, onOpen } = props;

  return (
    <Stack
      direction="column"
      spacing={1}
    >
      {files.map((file: any, index: number) => (
        <FileListItem
          key={index}
          sessionId={sessionId}
          file={file}
          onDelete={onDelete}
          onOpen={onOpen}
        />
      ))}
    </Stack>
  );
}
