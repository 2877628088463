import React from 'react';
import { Box } from '@mui/material';

export default function UploadProgress(props: any) {
  const { uploadProgress, sx } = props;

  return (
    <Box
      sx={{
        backgroundColor: '#EEEEEE',
        visibility: (uploadProgress > 0 && uploadProgress < 1 ? 'visible' : 'hidden'),
        ...sx,
      }}
    >
      <Box
        sx={{
          height: '5px',
          backgroundColor: '#0000FF',
          width: uploadProgress,
        }}
      >
      </Box>
    </Box>
  );
}
