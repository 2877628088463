import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Loading } from './components/Loading';
import AppRoutes from './AppRoutes';

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 0,
        retry: 0,
      },
    },
  });

  return (
    <QueryClientProvider
      client={queryClient}
    >
      <Suspense
        fallback={<Loading loading={true} />}
      >
        <AppRoutes/>
      </Suspense>
    </QueryClientProvider>
  );
}
