import React from 'react';
import { Box } from '@mui/material';
import FileList from './FileList';

export default function Files(props: any) {
  const { sessionId, files, onDelete, onOpen } = props;

  return (
    <Box>
      {!!files.length &&
        <FileList
          sessionId={sessionId}
          files={files}
          onDelete={onDelete}
          onOpen={onOpen}
        />
      }
    </Box>
  );
}
