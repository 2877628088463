const body = document.querySelector('body');

const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    const height = entry.contentRect.height;
    const message = { source: 'erezept', action: 'resize', height }

    window.top.postMessage(message, '*')
  }
});

resizeObserver.observe(body);
