import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Frontend from './frontend';
import Backend from './backend';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Frontend/>,
  },
  {
    path: "/view",
    element: <Backend/>,
  },
  {
    path: "/view/:sessionId",
    element: <Backend/>,
  },
]);

export default function AppRoutes() {
  return (
    <RouterProvider
      router={router}
    />
  );
}
