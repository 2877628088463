import React from 'react';
import { Modal, Box, Card, Fade } from '@mui/material';
import DataMatrix from 'react-datamatrix-svg';

const style = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 4,
};

function LightBox(props: any, ref: any) {
  const [ open, setOpen ] = React.useState(false);
  const [ url, setUrl ] = React.useState<string>('');
  const [ code, setCode ] = React.useState<string>('');

  React.useImperativeHandle(ref, () => {
    return {
      showImage: async (url: string) => {
        setUrl(url);
        setCode('');
        setOpen(true);
      },
      showCode: async (code: string) => {
        setUrl('');
        setCode(code);
        setOpen(true);
      },
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Card sx={{ height: 1 }}>
            <Box alignItems="center" justifyContent="center" width={1} height={1} display="flex">
              {url &&
                <img src={url} crossOrigin="anonymous" />
              }
              {code &&
                <DataMatrix msg={code} dim={300} pal={["#000000", "#FFFFFF"]} imgAttrs={{ style: { marginRight: '40px' } }} />
              }
            </Box>
          </Card>
        </Box>
      </Fade>
    </Modal>
  );
}

export default React.forwardRef(LightBox);
