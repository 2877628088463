import React from 'react';
import { Button } from '@mui/material';

export default function DeleteAllFilesButton(props: any) {
  const { label, onClick } = props;

  return (
    <Button
      variant="contained"
      onClick={onClick}
    >
      {label}
    </Button>
  );
}
