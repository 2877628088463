import React from 'react';
import { Alert, Box, Stack, Typography } from '@mui/material';
import Files from './Files';
import { FileService } from '../services';
import { DefaultError, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Loading } from '../components/Loading';
import DeleteAllFilesButton from './DeleteAllFilesButton';
import { File } from '../models';
import dayjs from 'dayjs';
import { ErrorInfo } from '../components/ErrorInfo';
import LightBox from '../components/LightBox';

export default function Backend() {
  const queryClient = useQueryClient();
  const { sessionId } = useParams();
  const lightBoxRef = React.useRef<any>(null);

  const query = useQuery<{ createdAt: string, expiresAt: string, files: File[] }>({
    queryKey: [ 'files' ],
    queryFn: () => FileService.getFiles(sessionId!),
  });

  const onOpen = (file: File, code: boolean) => {
    if (code) {
      lightBoxRef.current?.showCode(file.code);
    } else {
      lightBoxRef.current?.showImage(`${process.env.REACT_APP_API_BASE_URL}/${sessionId}/${file.id}/${file.name}`);
    }
  };

  const deleteMutation = useMutation<unknown, DefaultError, File, unknown>({
    mutationFn: async (data: File) => FileService.deleteFile(sessionId!, data.id, data.name),
    onSuccess: (data: any, variables: any) => {
      queryClient.invalidateQueries({
        queryKey: [ 'files' ],
      });
    }
  });

  const onDelete = (file: File) => {
    deleteMutation.mutate(file);
  };

  const deleteAllMutation = useMutation({
    mutationFn: async () => FileService.deleteSession(sessionId!),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ 'files' ],
      });
    }
  });

  const onDeleteAll = () => {
    deleteAllMutation.mutate();
  };

  return (
    <Loading loading={query.isLoading}>
      {query.isSuccess && !!query.data.files.length &&
        <Stack
          direction="column"
          spacing={1}
          sx={{ m: 1 }}
        >
          <Alert severity="warning">
            <Typography>
              Sollte Ihr Scanner den Rezept-Code auf dem Bildschirm nicht scannen können, aktivieren Sie bitte den Bildschirm-Scan-Modus am Scanner.
            </Typography>
            <Typography fontSize="small">
              (<strong>Achtung, Admin Einstellung!</strong> z.B. bei Honeywell 7580,7680 unter <a href="https://prod-edam.honeywell.com/content/dam/honeywell-edam/sps/ppr/de-de/public/products/discontinued/scanners/genesis-7580g/sps-ppr-7580-ug.pdf?download=false" target="_blank" rel="noreferrer">“Presentation - Mobile Phone”</a> Seite 3-10.)
            </Typography>
          </Alert>          
          <Alert severity="info">
            Die Daten sind noch {dayjs(query.data.expiresAt).diff(dayjs(), 'day')} Tage verfügbar.
          </Alert>          
          <Files
            sessionId={sessionId}
            files={query.data.files}
            onDelete={onDelete}
            onOpen={onOpen}
          />
          <Box>
            <DeleteAllFilesButton
              label="Dateien löschen"
              onClick={onDeleteAll}
            />
          </Box>
        </Stack>
      }
      {(query.isError || (query.isSuccess && !query.data.files.length)) &&
        <ErrorInfo title="Es wurden keine Daten gefunden" info="Entweder ist der Link falsch oder die Daten sind nicht mehr verfügbar" />
      }
      <LightBox ref={lightBoxRef} />
    </Loading>
  );
}
