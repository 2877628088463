import React from 'react';
import { Stack } from '@mui/material';
import { File } from '../models';
import FileListItem from './FileListItem';

export default function FileList(props: any) {
  const { files, onDelete } = props;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ marginTop: '10px' }}
    >
      {files.map((file: File, index: number) => (
        <FileListItem
          key={index}
          file={file}
          onDelete={onDelete}
        />
      ))}
    </Stack>
  );
}
