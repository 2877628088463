import { BrowserCodeReader, BrowserDatamatrixCodeReader, HTMLVisualMediaElement, IScannerControls } from '@zxing/browser';
import { DecodeContinuouslyCallback } from '@zxing/browser/esm/common/DecodeContinuouslyCallback';
import { ArgumentException, ChecksumException, FormatException, NotFoundException } from '@zxing/library';

export class Reader extends BrowserDatamatrixCodeReader {
  public captureCanvas: any;

  public scan(
    element: HTMLVisualMediaElement,
    callbackFn: DecodeContinuouslyCallback,
    finalizeCallback?: (error?: Error) => void,
  ): IScannerControls {
    Reader.checkCallbackFnOrThrow2(callbackFn);

    /**
     * The HTML canvas element, used to draw the video or image's frame for decoding.
     */
    let captureCanvas: any = BrowserCodeReader.createCaptureCanvas(element);

    this.captureCanvas = captureCanvas;

    /**
     * The HTML canvas element context.
     */
    let captureCanvasContext: any;
    try {
        captureCanvasContext = captureCanvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
    } catch (e) {
        captureCanvasContext = captureCanvas.getContext('2d');
    }

    // cannot proceed w/o this
    if (!captureCanvasContext) {
      throw new Error('Couldn\'t create canvas for visual element scan.');
    }

    const disposeCanvas = () => {
      captureCanvasContext = undefined;
      captureCanvas = undefined;
      this.captureCanvas = undefined;
    };

    let stopScan = false;
    let lastTimeoutId: undefined | ReturnType<typeof setTimeout>;

    // can be called to break the scan loop
    const stop = () => {
      stopScan = true;
      clearTimeout(lastTimeoutId);
      disposeCanvas();
      if (finalizeCallback) { finalizeCallback(); }
    };

    // created for extensibility
    const controls = { stop };

    // this async loop allows infinite (or almost? maybe) scans
    const loop = () => {

      if (stopScan) {
        // no need to clear timeouts as none was create yet in this scope.
        return;
      }

      try {
        BrowserCodeReader.drawImageOnCanvas(captureCanvasContext, element);
        const result = this.decodeFromCanvas(captureCanvas);
        callbackFn(result, undefined, controls);
        lastTimeoutId = setTimeout(loop, this.options.delayBetweenScanSuccess);
      } catch (error: any) {

        callbackFn(undefined, error, controls);

        const isChecksumError = error instanceof ChecksumException;
        const isFormatError = error instanceof FormatException;
        const isNotFound = error instanceof NotFoundException;

        if (isChecksumError || isFormatError || isNotFound) {
          // trying again
          lastTimeoutId = setTimeout(loop, this.options.delayBetweenScanAttempts);
          return;
        }

        // not trying again
        disposeCanvas();
        if (finalizeCallback) { finalizeCallback(error); }
      }
    };

    // starts the async loop
    loop();

    return controls;    
  } 

  private static checkCallbackFnOrThrow2(callbackFn: DecodeContinuouslyCallback) {
    if (!callbackFn) {
      throw new ArgumentException('`callbackFn` is a required parameter, you cannot capture results without it.');
    }
  }
}
