import React from 'react';
import { Box, IconButton, SvgIcon, Typography } from '@mui/material';
import DocumentIcon from '@heroicons/react/24/solid/DocumentIcon';
import ArrowDownCircleIcon from '@heroicons/react/24/solid/ArrowDownCircleIcon';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import DataMatrix from 'react-datamatrix-svg'
import CopyButton from './CopyButton';

const style = {
  padding: '10px',
  borderRadius: 1,
  backgroundColor: '#fafafa',
  color: '#000000',
  outline: 'none',
};

export default function FileListItem(props: any) {
  const { sessionId, file, onDelete, onOpen } = props;
  const ext = file.name.split('.').pop();

  return (
    <Box
      sx={style}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <SvgIcon
          sx={{ fontSize: '2rem', marginLeft: '10px', marginRight: '10px' }}
        >
          <DocumentIcon/>
        </SvgIcon>
        <Typography
          variant="body1"
        >
          {file.name}
        </Typography>
        <Box
          sx={{ marginLeft: 'auto' }}
        >
          {file.code &&
            <CopyButton file={file} />
          }
          <IconButton
            href={`${process.env.REACT_APP_API_BASE_URL}/${sessionId}/${file.id}/${file.name.startsWith('E-Rezept-Scan ') ? 'code.txt' : file.name}/download`}
            target="_blank"
          >
            <SvgIcon
              sx={{ fontSize: '1.6rem' }}
            >
              <ArrowDownCircleIcon/>
            </SvgIcon>
          </IconButton>
          <IconButton
            onClick={(event) => { event.preventDefault(); onDelete(file); }}
          >
            <SvgIcon
              sx={{ fontSize: '1.6rem' }}
            >
              <TrashIcon/>
            </SvgIcon>
          </IconButton>
        </Box>
      </Box>
      {(file.code || [ 'png', 'bmp', 'dib', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp' ].includes(ext)) &&
        <Box
          sx={{ marginLeft: '40px', marginTop: '10px' }}
        >
          {file.code &&
            <DataMatrix msg={file.code} dim={200} imgAttrs={{ style: { marginRight: '40px', cursor: 'pointer' }, onClick: () => onOpen(file, true) }} />
          }
          {[ 'png', 'bmp', 'dib', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp' ].includes(ext) &&
            <img src={`${process.env.REACT_APP_API_BASE_URL}/${sessionId}/${file.id}/${file.name}`} alt="" onClick={() => onOpen(file, false)} style={{ maxWidth: '200px', maxHeight: '200px', cursor: 'pointer' }} crossOrigin="anonymous" />
          }
        </Box>
      }
    </Box>
  );
}
