import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoadingRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '50px',
  paddingBottom: '50px',
}));

export const Loading = (props: any) => {
  const { loading, children } = props;

  return (
    <>
      {loading &&
        <LoadingRoot>
          <CircularProgress/>
        </LoadingRoot>
      }
      {!loading && children}
    </>
    );
};
