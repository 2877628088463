import React from 'react';
import { Box } from '@mui/material';
import FileList from './FileList';

export default function Files(props: any) {
  const { files, onDelete } = props;

  return (
    <Box>
      {!!files.length &&
        <FileList
          files={files}
          onDelete={onDelete}
        />
      }
    </Box>
  );
}
