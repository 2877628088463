import React from 'react';

export default function useMessages() {
  const [ messages, setMessages ] = React.useState<any[]>([]);
  const [ nextId, setNextId ] = React.useState(1);

  const removeMessage = (id: number) => {
    setMessages((messages: any[]) => {
      const newMessages = [ ...messages ];

      newMessages.splice(newMessages.indexOf(newMessages.find((message: any) => message.id === id)), 1);

      return newMessages;
    });
  };

  const fadeMessage = (id: number) => {
    setMessages((messages: any[]) => {
      const newMessages = [ ...messages ];
      const message = newMessages.find((message: any) => message.id === id);

      message.fade = true;

      setTimeout(() => removeMessage(message.id), 600);

      return newMessages;
    });
  };

  const addMessage = (severity: any, text: string) => {
    const id = nextId;
    const newMessage = { id, severity, text, fade: false };
    const newMessages = [ ...messages ];

    newMessages.unshift(newMessage);

    setMessages(newMessages);
    setNextId(nextId + 1);

    setTimeout(() => fadeMessage(id), 3000);
  };
  
  return {
    messages,
    addMessage,
  };
}
