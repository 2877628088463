import React from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import ExclamationTriangleIcon from '@heroicons/react/24/solid/ExclamationTriangleIcon';

export const ErrorInfo = (props: any) => {
  const { title, info } = props;
  
  return (
    <Box
      role="alert"
      sx={{
        alignSelf: 'center',
        justifySelf: 'center',
        margin: 'auto',
        textAlign: 'center',
        padding: '16px 0',
      }}
    >
      <SvgIcon
        fontSize="large"
      >
        <ExclamationTriangleIcon/>
      </SvgIcon>
      <Typography
        variant={'h5'}
        mt={1}
      >
        {title}
      </Typography>
      <Typography
        variant={'body1'}
        mt={1}
      >
        {info}
      </Typography>
    </Box>
  );
};
