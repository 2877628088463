import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 500000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const responseBody = (response: any) => response.data;

const requests = {
  get: (url: string, headers?: any) =>
    instance.get(url, headers).then(responseBody),

  post: (url: string, body: any, headers?: any) => instance.post(url, body, headers).then(responseBody),

  put: (url: string, body: any, headers?: any) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url: string, body: any, headers?: any) => instance.patch(url, body, headers).then(responseBody),

  delete: (url: string, headers?: any) => instance.delete(url, headers).then(responseBody),
};

export default requests;
