import React from 'react';
import { Button as MaterialButton } from '@mui/material';

export default function Button(props: any) {
  const { label, disabled, onClick, startIcon } = props;

  return (
    <MaterialButton
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
    >
      {label}
    </MaterialButton>
  );
}
