import React from 'react';
import { Alert, Stack } from '@mui/material';

export default function Messages(props: any) {
  const { messages, sx } = props;

  return (
    <Stack
      direction="column"
      sx={sx}
    >
      {messages.map((message: any) => (
        <Alert
          severity={message.severity}
          sx={{
            mt: 1.5,
            transition: 'opacity 0.5s',
            opacity: (message.fade ? 0 : 1),
          }}
        >
          {message.text}
        </Alert>          
    ))}
    </Stack>
  );
}
