import React from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import DocumentDuplicateIcon from '@heroicons/react/24/solid/DocumentDuplicateIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function CopyButton(props: any) {
  const { file } = props;
  const [ open, setOpen ] = React.useState(false);
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const onCopy = () => {
    setOpen(true);
    timeout.current = setTimeout(() => {
      setOpen(false);
      timeout.current = null;
    }, 10000);
  };

  React.useEffect(() => () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, []);

  return (
    <CopyToClipboard text={file.code} onCopy={onCopy}>
      <Tooltip title="In die Zwischenablage kopiert (in der Warenwirtschaft in das Feld zum Einfügen des E-Rezepts gehen und einfügen (Tastenkombination Strg+v)." open={open} placement="left" arrow>
        <IconButton>
          <SvgIcon
            sx={{ fontSize: '1.6rem' }}
          >
            <DocumentDuplicateIcon/>
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
}
